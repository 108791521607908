import React, { useEffect, useState } from "react";

const App = () => {

    return(
        <div className="app">
           <video controls autoPlay playsInline muted loop 
           poster="https://gateway.pinata.cloud/ipfs/QmP5RUu8NvadCWYpW2dRsD3kc881hHGf1CuKKTuz4rXyMf"
           className="mt-4 w-full max-w-lg">
             <source src="https://gateway.pinata.cloud/ipfs/QmQJAsPUECorvtaDrit64xgqPQuDghFz8towH4BbCn1zPj" 
             type="video/mp4" />
             Ваш браузер не поддерживает видео.
        </video>
        </div>
    )
}

export default App;